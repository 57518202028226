body {
    padding: 0;
    margin: 0;
}

@media print {
    body {
        visibility: hidden;
    }

    #section-to-print {
        visibility: visible;
        position: absolute;
        left: 0;
        top: 1rem;
    }
}